import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    main: {
        background: 'white',
        paddingLeft: '10%',
        paddingRight: '10%',
        marginTop: 100
    },
    socialMedia: {
        margin: 10,
        fontWeight: 600,
        textDecorationColor: 'black',
        // textDecoration: 'underline',
        fontSize: 'calc((1 - 1) * 1vw + 1.2rem)',
        borderBottom: '1.5px solid',
        cursor: 'pointer'
    },
    titleLabel: {
        textAlign: 'center',
        width: '100%',
        margin: '0px',
        marginTop: 15,
        fontSize: 'calc((1 - 1) * 1vw + 1rem)'
    },
    descriptionLabel: {
        textAlign: 'center',
        width: '100%',
        marginTop: 15,
        fontSize: 'calc((1 - 1) * 1.2vw + 1rem)',
        paddingRight: '5%',
        paddingLeft: '5%',
        fontWeight: 400,
        letterSpacing: '.02em'
    },
    contactUsLabel: {
        fontSize: '1rem',
        borderBottom: '1.5px solid',
        cursor: 'pointer',
        textAlign: 'center',
        margin: 0,
        marginBottom: 40
    }
}))

function Footer () {
    const classes = useStyles();

    const openSocialMedia = (url) => {
        window.open(url, '_blank');
    }

    return (<Grid container className={classes.main}>
        <Grid container justifyContent="center">
            <Grid item className={classes.socialMedia} onClick={() => openSocialMedia("https://t.me/rarezofficial")} >Telegram</Grid>
            <Grid item className={classes.socialMedia} onClick={() => openSocialMedia("https://twitter.com/rarez_official")} >Twitter</Grid>
            <Grid item className={classes.socialMedia} onClick={() => openSocialMedia("https://emanate.live/")} >emanate</Grid>
        </Grid>
        <strong className={classes.titleLabel}>RAREZ is owned by Blockchain Music Pty Ltd. All trademarks copyright 2021.</strong>
        <p className={classes.descriptionLabel}>Our team acknowledges the traditional owners and custodians of country throughout Australia and their continuing connection to land, culture and community. We pay our respects to elders past, present and future.</p>
        <Grid container justifyContent="center">
            <p className={classes.contactUsLabel} onClick={() => openSocialMedia("mailto:nft@rarez.io")}>Contact Us</p>
        </Grid>

    </Grid>)
}

export default Footer
