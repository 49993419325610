import { useContext, createRef, useState } from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ProjectContext } from '../../context/projectContext';
// import BrowserIpfs from '../../services/BrowserIpfs';
// import Paper from "@material-ui/core/Paper";

// import image from '../../images/standard.png'
// import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
    main: {
        background: 'white',
        flexGrow: 1,
        height: 'auto',
        padding: '2%',
        margin: '10px'
    },
    img: {
        // height: '230px',
        // height: '30vh',
        height: 'auto',
        width: '100%'
        // objectFit: 'cover',
    },
    containImg: {
        background: 'gray',
        width: '100%'
    },
    contItem: {
        // padding: '10px'
        paddingLeft: '5px',
        paddingRight: '5px',
        paddingBottom: '5px',
        // background: 'green'
    },
    contCont: {
        // background: 'blue',
        padding: '5px'
    },
    contImg: {
        
        // background: 'orange',
        textAlign: 'center',
    },
    cardLabelInfoDesc: {
        color: 'white',
        margin: '0px',
        padding: '0px',
        marginTop: '2px',
        wordWrap: 'break-word',
        width: '100%',
        minHeight: '40px'
    },
    cardLabelInfo: {
        color: 'white',
        margin: '0px',
        padding: '0px',
        marginTop: '2px',
        wordWrap: 'break-word',
        width: '100%'
    },
    cardLabelInfoBold: {
        color: 'white',
        margin: '0px',
        padding: '0px',
        marginTop: '2px',
        fontWeight: '700',
        fontSize: '1.2rem',
        wordWrap: 'break-word',
        width: '100%',
        cursor: 'pointer',
        textDecoration: 'underline',
        textDecorationColor: 'white'
    },
    containerCircularProgress: {
        width: '100%',
        // textAlign: 'center',
        minHeight: '40vh',
        // background: 'green',
        position: 'relative'
    },
    circularProgress: {
        position: 'absolute',
        width: '20%',
        // textAlign: 'center',
        color: 'white',
        height: '20%',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        margin: 'auto',
        // margin: 0,
        // background: 'blue'
    }
}));

const {
    REACT_APP_IPFS_URL: IPFS_URL,
    REACT_APP_ATOMIC_HUB_URL: ATOMIC_HUB_URL,
} = process.env;
// const Transition = forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
//   });

function CardItem (props) {
    const classes = useStyles();
    const {card} = props
    const { domain } = useContext(ProjectContext)
    const [domainConfigs] = domain
    const myVideo = createRef() 
    // const {packageItem, openPackage} = props
    // const [showingInfo, setShowingInfo] = useState(false)
    
    const isVideoAsset = card.template.immutableData.type.includes('video')
    const isImageAsset = card.template.immutableData.type.includes('image')
    // console.log('isVideoAsset', isVideoAsset)
    const [sourceLoaded, setSourceLoaded] = useState(false)

    const getSourceUrl = (id) => {
        return `${IPFS_URL}/api/v0/cat?arg=${id}`
    }

    const goToTemplate = () => {
        // console.log('templateGo ', card)
        const url = `${ATOMIC_HUB_URL}/explorer/template/${domainConfigs.collection}/${card.template.templateId}`
        window.open(url, '_blank');
    }

    const autoPlayVideo = () => {
        setSourceLoaded(true)
        console.log('autoPlayVideo', myVideo)
        myVideo.current.play()
    }

    const showSource = () => {        
        if (isVideoAsset) {
            return (
                <video onLoadedData={() => autoPlayVideo()} ref={myVideo} loop muted className={classes.img}>
                    <source src={getSourceUrl(card.template.immutableData.video)} type={card.template.immutableData.type} />
                </video>
            )
        } else if (isImageAsset) {
            return (
                <img onLoadedData={() => setSourceLoaded(true)} onLoad={() => setSourceLoaded(true)}
                    alt="packageImg"
                    src={getSourceUrl(card.template.immutableData.img)}
                    // src={getSourceUrl(packageItem.data.img)}
                    className={classes.img}
                    effect="blur"
                /> 
            )
        }
    }

        return (
        <Grid className={classes.contItem}>
            <Grid container direction="column" justifyContent="center" className={classes.contCont}>
                <div className={classes.contImg}>
                    {showSource()}
                    {!sourceLoaded ? <div className={classes.containerCircularProgress}>
                            <CircularProgress size="lg" className={classes.circularProgress} />
                        </div> : null}
                </div>
                <p className={classes.cardLabelInfoDesc}>
                    {card.template.immutableData.description}
                </p> 
                <p className={classes.cardLabelInfoBold} onClick={() => goToTemplate()}>
                    {card.template.immutableData.name}
                </p> 
                <Grid container justifyContent="space-between" fullWidth>
                    <Grid item xs>
                        <p className={classes.cardLabelInfo}>
                            {card.template.immutableData.rarity}
                        </p> 
                    </Grid>
                    <Grid item xs justifyContent="flex-end">
                        <p className={classes.cardLabelInfo} style={{textAlign: 'right'}}>
                            Id: {card.template.immutableData.cardid}
                        </p>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
        )
}

export default CardItem