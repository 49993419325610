import { Card, Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// import BrowserIpfs from '../../services/BrowserIpfs';


// import image from '../../images/standard.png'
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
    main: {
        background: 'white',
        flexGrow: 1,
        height: 'auto',
        padding: '2%',
        margin: '10px'
    },
    title: {
        fontWeight: 'bold',
        fontSize: '1.2rem',
        margin: '0px',
        marginBottom: '0px',
        marginTop: '5px',
        letterSpacing: '0.02em',
        // fontFamily: 'ITCAvantGardeStdBold'
        // fontFamily: 'ITCAvantGardeStdDemi'
    },
    subtitle: {
        margin: '0px',
        marginBottom: '5px',
        fontWeight: '600',
        // fontWeight: '700',
        fontSize: '12px',
        letterSpacing: '0.1em'
    },
    img: {
        height: '220px',
        // objectFit: 'cover',
    },
    containerActions: {
        marginTop: '5px'
        // flexGrow: 1,
        // background: 'green'
    },
    openButton: {
        width: '25%',
        [theme.breakpoints.down('sm')]: {
            width: "50%",
        },
        // fontSize: '0.7rem !important',
        // letterSpacing: '0.05em'
        // theme.b
    },
    openLabelBtn: {
        margin: 0,
        fontWeight: '600',
        fontSize: '0.7rem',
        paddingTop: '0.15rem'
    },
    infoButton: {
        textDecoration: 'underline',
        fontWeight: '800',
        cursor: 'pointer',
        fontSize: '0.7rem',
        letterSpacing: '0.05em'
        // background: 'gray',
        // height: '100%'
    },
    containerMedia: {
        position: 'relative',
    },
    containerInfo: {
        background: 'black',
        height: 'auto',
        width: '100%',
        position: 'absolute',
        bottom: 0,
        padding: '10px'
    },
    provP: {
        margin: 0,
        color: 'white',
        fontWeight: '500'
    },
    containImg: {
        background: 'gray',
        width: '100%',
        padding: 15,
        backgroundSize: 'cover',
    },
    contItem: {
        // padding: '10px'
        paddingLeft: '5px',
        paddingRight: '5px',
        paddingBottom: '5px'
    }
}));

const {
    REACT_APP_IPFS_URL: IPFS_URL,
} = process.env;

// const Transition = forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
//   });

function PackageItem (props) {
    const classes = useStyles();
    const {packageItem, openPackage, packTemplate, claimPackage} = props
    const [showingInfo, setShowingInfo] = useState(false)
    // console.log('props', props)


    const getImageUrl = (driveUrl) => {
        // const id = driveUrl.replace('https://drive.google.com/file/d/', '').replace('/view?usp=sharing', '')
        // const url = 'https://drive.google.com/uc?export=view&id='+id
        // return url
        // const file = await BrowserIpfs.retrieve(driveUrl)
        // console.log('File',driveUrl, file, window.URL.createObjectURL(file.payload))
        // return URL.createObjectURL(file)
        const url = `${IPFS_URL}/api/v0/cat?arg=${driveUrl}`
        // console.log('urlImage', url)
        return url
    }


    // if (matchPackage) {
        return (
        <Grid className={classes.contItem} item xs={12} sm={6} md={4} lg={4}>
            <Card container className={classes.main}>
                <div onClick={() => setShowingInfo(false)}>
                    <p className={classes.title}>{packageItem.collection.collection_name}</p>
                    <p className={classes.subtitle}>{packageItem.data.name} | Series {packageItem.data.series}</p>
                </div>
                <Grid container justifyContent="center"  className={classes.containerMedia} onClick={() => setShowingInfo(false)}>
                    <Grid container justifyContent="center" className={classes.containImg} style={{ backgroundImage: `url("${packTemplate.packBackground}")` }}>
                        <img
                        alt="packageImg"
                        src={getImageUrl(packageItem.data.img)}
                        className={classes.img}
                        />
                    </Grid>
                    {showingInfo ?
                        <Grid className={classes.containerInfo}>
                            {packTemplate && packTemplate.description ? packTemplate.description.map(desc => {
                                return <p key={desc} className={classes.provP}>{desc}</p>
                            }): null

                            }
                            {/* <p className={classes.provP}>{packageItem.data.description}</p> */}
                            <br />
                            <p className={classes.provP}>This package contains {packageItem.data.contains}</p>
                        </Grid>
                        : null
                    }
                </Grid>
                <Grid className={classes.containerActions} container direction="row" justifyContent="space-between" alignItems="baseline">
                    {/* <p>Pack Infos</p> */}
                    <p className={classes.infoButton} onClick={() => setShowingInfo(!showingInfo)}>
                        Pack Info
                    </p>
                    {packageItem.notClaimed ?
                        <Button style={{ background: packTemplate.buttonColor}} variant="contained" size="small" className={classes.openButton} onClick={() => claimPackage(packageItem)}>
                            <p className={classes.openLabelBtn}>
                                Claim
                            </p>
                        </Button>
                        :
                        <Button style={{ background: packTemplate.buttonColor}} variant="contained" size="small" className={classes.openButton} onClick={() => openPackage(packageItem)}>
                            <p className={classes.openLabelBtn}>
                                Open
                            </p>
                        </Button>
                    }

                </Grid>
            </Card>
        </Grid>
        )
    // } else {
    //     return null
    // }
}

export default PackageItem