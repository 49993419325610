import RequestApi from "./RequestApi";
// REACT_APP_BUCKET_URL
const {
    REACT_APP_BUCKET_URL: bucket_ur,
  
  } = process.env;

class ProjectApi {
    constructor({ collection, series }) {
        this.api = new RequestApi(bucket_ur);
        this.collection = collection
        this.series = series
    }

    async getProjectConfigs() {
        // const { data } = await this.api.get('rareztest111/series5/webapp1.json')
        const { data } = await this.api.get(`/${this.collection}/${this.series}/webapp.json`)
        return data
    }

    async getTemplates() {
        // const { data } = await this.api.get('rareztest111/series5/templates.json')
        const { data } = await this.api.get(`/${this.collection}/${this.series}/templates.json`)
        return data
    }


}

export default ProjectApi