import React, { useContext, useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
// import Container from '@material-ui/core/Container';
import { Button, useMediaQuery, IconButton, Drawer, List } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ExitIcon from '@material-ui/icons/ExitToApp';
// import rarezIcon from './icons/rarez-black.png';
import { useHistory, useLocation } from 'react-router-dom';
import {ProjectContext} from '../context/projectContext';
import {
    ListItem,
    Grid
    // Typography
} from '@material-ui/core';
import { NavLink } from 'react-router-dom';
// import { Link } from 'react-router-dom';

import { UALContext } from 'ual-reactjs-renderer';
import Footer from './Footer';

// function CustomNavLink({ label, to, activeOnlyWhenExact }) {
//     let match = useRouteMatch({
//       path: to,
//       exact: activeOnlyWhenExact
//     });
  
//     return (
//       <div className={match ? "active" : ""}>
//         {match && "> "}
//         <Link to={to}>{label}</Link>
//       </div>
//     );
//   }
  

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    flex1: {
        flexGrow: 1,
        // background: 'blue',
        // flex: 1
    },
    root2: {
        flexGrow: 1,
    },
    containerLogged: {
        marginBottom: 1
    },
    iconBtn: {
        marginLeft: 5,
        marginBottom: 5,
        fontSize: '1.5rem',
        cursor: 'pointer',
        // paddingBottom: 5
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    accountName: {
        fontWeight: 600,
        fontSize: '1.2rem',
        marginLeft: 20
    },
    title: {
        flexGrow: 1,
        flexDirection: 'row',
        // background: 'green'
    },
    appbar: {
        boxShadow: 'none',
        padding: '0px',
        // minHeight: '40px !important',
        background: theme.palette.primary.main,
        height: 80,
        margin: '0px !important'
    },
    toolBar: {
        height: 80,
    },
    signInLabel: {
        fontSize: '1rem',
        fontWeight: 600,
        margin: '2px',
        // fontFamily: 'ITCAVGGSB !important'
    },
    appIcon: {
        height: '40px',
        flexGrow: 1,
    },
    bottomAppbar: {
        top: 'auto',
        bottom: 0,
        boxShadow: 'none',
        background: theme.palette.primary.main,
        marginTop: 'solid 0px green',
    },
    colorBarBottom: {
        // background: 'linear-gradient(45deg, #48b439, #3d9631)',
        // height: '5px',
    },
    iconContainerRarez: {
        // flexGrow: 1,
       background: 'green'
    },
    containerFlexOptions: {
        // flexGrow: 1,
        // background: 'blue',
        display: 'flex',
        // justifyContent: 'center',
        alignItems: 'center',
    },
    rarezIcon: {
        maxHeight: '40px',
        width: '120px',
        margin: '10px',
        marginLeft: '5px',
        cursor: 'pointer'
    },
    defaultLink: {
      color: 'black',
      textDecoration: 'none',
      fontWeight: '700',
    //   fontSize: '1.5rem !important'
    },
    activeLink: {
        color: 'black',
        borderBottom: '1.5px solid',
        // textDecoration: 'none',
        // textDecoration: 'underline',
        // textDecorationColor: 'black',
        // textDecorationStyle: 'underline'
    },
    fontOptions: {
      fontWeight: '200',
      fontSize: 'calc((1.1 - 1) * 1.2vw + 1rem)',
      margin: '0px'
    },
    btnList: {
        padding: 0,
        paddingLeft: 2,
        paddingRight: 2,
        marginLeft: 8,
        marginRight: 8,
        cursor: 'pointer'
    },
    btnDisabled: {
        padding: 0,
        paddingLeft: 8,
        paddingRight: 8,
        marginLeft: 4,
        marginRight: 4,
        cursor: 'not-allowed !important',
        pointerEvents: 'none',
        color: 'gray'

    }
    // containerBox: {
    //     // marginTop: '40px',
    //     // height: '80px',
    //     maxHeight: '80vh',
    //     overflow: 'auto',
    //     background: 'blue !important'
    // }
}));

const {
    REACT_APP_ATOMIC_HUB_URL: ATOMIC_HUB_URL,
} = process.env;

export default function MainLayout(props) {
    const { children } = props;
    const classes = useStyles();
    const ual = useContext(UALContext);
    const { domain, configs } = useContext(ProjectContext);
    const [statusDrawer, setStatusDrawer] = useState(false)
    const [domainConfigs] = domain
    const [projectConfigs] = configs
    const history = useHistory()
    let location = useLocation();
    const isMobile = useMediaQuery('(max-width:850px)');

    useEffect(() => {
        if (location !== '/open' && ual && ual.activeUser) {
        //   console.log('Redirecting to Open')
          history.push('/open')
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [ual])

    function closeDrawer () {
        setStatusDrawer(false)
    }

    function isLogged () {
        return (ual && ual.activeUser)
    }
    // console.log('ual', ual)

    const onPressLogin = async () => {
        console.warn('logginSetted');
        localStorage.setItem('autoLogin', true);
        ual.showModal();
    };

    const onPressLogout = () => {
        localStorage.removeItem('autoLogin')
        localStorage.removeItem('authIndex')
        ual.logout()
    }

    const goToHome = () => {
        history.replace('/')
        closeDrawer()
    }

    const redirectToInventory = () => {
        const url = `${ATOMIC_HUB_URL}/profile/${ual.activeUser.accountName}?collection_name=${domainConfigs.collection}`

        window.open(url, '_blank');
        closeDrawer()
    }

    const redirectToFaq = () => {
        const url = projectConfigs.faq.input

        window.open(url, '_blank');
        closeDrawer()
    }
    // itc-avant-garde-gothic-pro
    return (
        <React.Fragment>
            <CssBaseline />
            <div className={classes.root2}>
                <Drawer anchor={"left"} open={statusDrawer} onClose={closeDrawer}>
                    <div className={classes.list}>
                        <List>
                            <div className={classes.flexs}>
                            </div>
                                {projectConfigs && projectConfigs.navBarLogo ?
                                    <img
                                        src={projectConfigs.navBarLogo.input}
                                        alt="rarez"
                                        className={classes.rarezIcon}
                                        onClick={goToHome.bind()}
                                    /> : null
                                }
                            <NavLink
                                exact
                                to="/open"
                                activeClassName={classes.activeLink}
                                className={classes.defaultLink}
                                onClick={closeDrawer.bind()}
                            >
                                <ListItem button>
                                    {/* <ListItemText primary="OPEN" /> */}
                                    <p className={classes.fontOptions}>OPEN</p>
                                </ListItem>
                            </NavLink>
                            <div
                                // exact
                                // to="/inventory"
                                // activeClassName={classes.activeLink}
                                className={classes.defaultLink}
                            >
                                <ListItem button disabled={!isLogged()} onClick={redirectToInventory}>
                                    <p className={classes.fontOptions}>INVENTORY</p>
                                </ListItem>
                            </div>
                            <NavLink
                                exact
                                to="/catalog"
                                activeClassName={classes.activeLink}
                                className={classes.defaultLink}
                                onClick={closeDrawer.bind()}
                            >
                                <ListItem button>
                                <p className={classes.fontOptions}>CATALOG</p>
                                </ListItem>
                            </NavLink>
                            <div
                                className={classes.defaultLink}
                            >
                                <ListItem button disabled={!projectConfigs} onClick={redirectToFaq}>
                                    <p className={classes.fontOptions}>FAQ</p>
                                </ListItem>
                            </div>
                        </List>
                    </div>
                </Drawer>
                <AppBar position="fixed" color="transparent" className={classes.appbar}>
                    <Toolbar className={classes.toolBar}>
                        <div className={classes.title}>
                            {isMobile ?
                                <div className={classes.containerFlexOptions}>
                                    <IconButton onClick={() => setStatusDrawer(true)} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                                        <MenuIcon />
                                    </IconButton>
                                </div>
                            : <div className={classes.containerFlexOptions}>
                                {projectConfigs && projectConfigs.navBarLogo ?
                                    <img
                                        src={projectConfigs.navBarLogo.input}
                                        alt="rarez"
                                        className={classes.rarezIcon}
                                        onClick={goToHome.bind()}
                                    />: null
                                }
                                <div className={classes.flex1}></div>
                                <NavLink
                                    exact
                                    to="/open"
                                    activeClassName={classes.activeLink}
                                    className={classes.defaultLink}
                                >
                                    <div className={classes.btnList}>
                                        {/* <ListItemText primary="OPEN" /> */}
                                        <p className={classes.fontOptions}>Open</p>
                                    </div>
                                </NavLink>
                                <div
                                    // exact
                                    // to="/inventory"
                                    // activeClassName={classes.activeLink}
                                    className={classes.defaultLink}
                                >
                                    <div className={isLogged() ? classes.btnList : classes.btnDisabled} onClick={redirectToInventory}>
                                        <p className={classes.fontOptions}>Inventory</p>
                                    </div>
                                </div>
                                <NavLink
                                    exact
                                    to="/catalog"
                                    activeClassName={classes.activeLink}
                                    className={classes.defaultLink}
                                >
                                    <div className={classes.btnList}>
                                    <p className={classes.fontOptions}>Catalog</p>
                                    </div>
                                </NavLink>
                                <div
                                    className={classes.defaultLink}
                                >
                                    <div className={projectConfigs ? classes.btnList : classes.btnDisabled} onClick={redirectToFaq}>
                                        <p className={classes.fontOptions}>FAQ</p>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                        {(isLogged()) ?
                        <div className={classes.containerLogged}>
                            <Grid item>
                                <Grid container direction="row" alignItems="center">
                                    <p className={classes.accountName}>
                                        {ual.activeUser.accountName}
                                    </p>
                                    <ExitIcon onClick={() => onPressLogout()} className={classes.iconBtn}/>
                                </Grid>
                            </Grid>
                            {/* <Button color="inherit" onClick={() => onPressLogout()}> */}
                                {/* <p className={classes.signInLabel}>
                                    LOGOUT
                                </p> */}
                            {/* </Button> */}
                        </div> :
                        <Button color="inherit" onClick={() => onPressLogin()}>
                            <p className={classes.signInLabel}>
                                SIGN UP / SIGN IN
                            </p>
                        </Button>
                        }
                    </Toolbar>
                </AppBar>
            </div>
            <Toolbar id="back-to-top-anchor" />
            <div className={classes.containerBox} >
                <Box>{children}</Box>
            </div>
            <Footer />
        </React.Fragment>
    );
}
