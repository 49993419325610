import { Grid, Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
    mainContainer: {
        height: 'auto',
        width: '100% !important',
        background: '#f8f8f8',
        padding: '10px'
    },
    card: {
        height: '10px',
        background: 'gray'
    },
    text: {
        // height: '15px',
        background: 'gray'
    }
}))

function SkeletonPackageItem () {
    const classes = useStyles();
    return (
        <Card className={classes.mainContainer}>
            <Skeleton width="30%" height={25} className={classes.text} animation="pulse" variant="text" />
            <Skeleton width="50%" height={20} className={classes.text} animation="pulse" variant="text" />
            <Skeleton width="100%"  height={220} className={classes.text} animation="wave" variant="rect" />
            <Grid container direction="row" justifyContent="space-between" alignItems="baseline">
                <Skeleton width="20%" height={40} className={classes.text} animation="pulse" variant="text" />
                <Skeleton width="40%" height={40} className={classes.text} animation="pulse" variant="text" />
            </Grid>


        </Card>
    )
}

export default SkeletonPackageItem