import ErrorBoundary from './errorHandler';
import { createContext, useState, Fragment, useEffect } from 'react';
import ProjectApi from '../services/ProjectApi';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import LoadingConfigsPage from '../components/LoadingConfigsPage';
import RequestApi from '../services/RequestApi';
const {
  REACT_APP_BUCKET_URL: bucketUrl
} = process.env;


export default function ProjectProvider (props) {
    const [projectConfigs, setProjectConfigs] = useState(undefined)
    const [templateConfigs, setTemplateConfigs] = useState(undefined)
    const [domainConfigs, setDomainConfigs] = useState(undefined)
    const [projectApi, setProjectApi] = useState(undefined)
    // const [hasError, setHasError] = useState(false)
    const [notification, setNotification] = useState({
        message: undefined,
        type: 'success',
        showing: false
    })

    // When project configs are got the static html was updated
    useEffect(() => {
        if (projectConfigs) {
            // console.log('new Configs', projectConfigs)
            const elFav = document.getElementById("customFav")
            elFav.href = projectConfigs.favicon.input

            const elDesc = document.getElementById("customDescription")
            elDesc.content = projectConfigs.htmlDescription.input

            const elTitle = document.getElementById("customTitle")
            elTitle.innerHTML = projectConfigs.htmlTitle.input
        }
    }, [projectConfigs])

    // Getting collection and series from map route using domain
    useEffect(() => {
        async function getCollectionAndSeries (url) {
            try {
                const api = new RequestApi(bucketUrl)
                const urlSplit = url.split('.')
                console.log('split route', urlSplit[0])
                const { data: route } = await api.get('/route.json')
                console.log('route', route)
                // console.log('route key', `${urlSplit[0]}/`)
                const domainRouter = route[urlSplit[0]]
                const domainRouterSplit = domainRouter.split('/')
                const collectionData = {
                    collection: domainRouterSplit[0],
                    series: domainRouterSplit[1],
                    domainRouterSplit,
                }
                setDomainConfigs(collectionData)
                setProjectApi(new ProjectApi(collectionData))
            } catch (e) {
                console.error('An error ocurred trying to get route using domain mapping', e)
            }
        }

        if (!domainConfigs) { // If doesn't have the domain configs
            let currentURL = window.location.href
            currentURL = currentURL.replace('https://', '')
            currentURL = currentURL.replace('http://', '')
            console.log('currentDomain', currentURL)
            // currentURL = 'deadmau5-s2.rarez.io'
            // currentURL = 'localhost:3000'
            getCollectionAndSeries(currentURL)
        }
    }, [domainConfigs])

    const showSuccessNotification = (message) => {
        setNotification({
            message,
            type: 'success',
            showing: true
        })
    }
    const showErrorNotification = (message) => {
        setNotification({
            message,
            type: 'error',
            showing: true
        })
    }
    const hideNotification = () => {
        setNotification({
            ...notification,
            showing: false
        })
    }

    return (
        <Fragment>
            <ProjectContext.Provider value={{
                configs: [projectConfigs, setProjectConfigs],
                templates: [templateConfigs, setTemplateConfigs],
                domain: [domainConfigs, setDomainConfigs],
                projectApi,
                showSuccessNotification,
                showErrorNotification
            }}>
                {(domainConfigs && projectApi) ? <Fragment>
                    <ErrorBoundary>
                        {props.children}
                    </ErrorBoundary>
                </Fragment> : <LoadingConfigsPage />}
                <Snackbar style={{ bottom: 20 }} open={notification.showing} autoHideDuration={15000} onClose={hideNotification}>
                    <MuiAlert elevation={6} variant="filled" onClose={hideNotification} severity={notification.type}>
                        {notification.message}
                    </MuiAlert>
                </Snackbar>
            </ProjectContext.Provider>
        </Fragment>
    )
}

export const ProjectContext = createContext();
