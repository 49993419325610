import { Component } from 'react'
import { Grid, Button } from '@material-ui/core';

class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Actualiza el estado para que el siguiente renderizado muestre la interfaz de repuesto
      console.log('getDerivedStateFromError', error)
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // También puedes registrar el error en un servicio de reporte de errores
      console.log('componentDidCatch', error, errorInfo)
    //   logErrorToMyService(error, errorInfo);
    }
    // function refreshPage () {
    //     window.location.reload(false);
    // }
  
    render() {
      if (this.state.hasError) {
          console.log('Rendering error')
        // Puedes renderizar cualquier interfaz de repuesto
        return <Grid container>
            <h1>Something went wrong.</h1>
            <Button onClick={() => window.location.reload()}>Reload Page</Button>
        </Grid>
      }
  
      return this.props.children; 
    }
  }

  export default ErrorBoundary