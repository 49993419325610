import eosJsRpc from './EosRpc';
//import RequestError from '../error/RequestError';
//import { Util } from '../util';
import BaseApi from './BaseApi';

class BaseEosApi extends BaseApi {
    constructor(activeUser, contractAccount) {
        super(activeUser);
        this.rpc = eosJsRpc;
        this.contractAccount = contractAccount;
    }

    /**
     * Makes the transaction with the blockchain
     * @param { Object } actions 
     */
    async transactFull(actions) {
        
        return this.activeUser.signTransaction(
            {
                actions,
            },
            {
                broadcast: true,
                blocksBehind: 3,
                expireSeconds: 30
            },
        );
        
        // catch (err) {
        //     const { cause } = err;
        //     if (cause) {
        //         if (cause.cause) console.warn(cause.cause);
        //         if (cause.cause && cause.cause.couse) console.warn(cause.cause.cause);
        //         //const code = Util.getProperty(cause, 'json.code', 0);
        //         //throw new RequestError(cause.message, code, err);
        //     }
        //     throw err;
        // }
    }

    /**
     * 
     * First method to make an transction
     * @param { String } name
     * @param { Object } data
     */
    async transact({ name, data }) {
        const action = await this._formatAction({
            name,
            data,
        });
        await this.transactFull([action]);
    }

    async _formatAction({ account, name, data }) {
        const mAccount = account || this.contractAccount;
        return {
            account: mAccount,
            name,
            authorization: [
                {
                    actor: await this.getAccountName(),
                    permission: 'active',
                },
            ],
            data,
        };
    }

    _getTableRows({
        code,
        scope,
        table,
        indexPosition,
        tableKey,
        lowerBound,
        upperBound,
        keyType,
        limit,
        reverse
    }) {
        return this.rpc.getTableRows({
            code,
            scope,
            table,
            indexPosition,
            tableKey,
            lowerBound,
            upperBound,
            keyType,
            limit,
            reverse
        })
    }

    async getTableRows(
        {
            code,
            scope,
            table,
            indexPosition,
            tableKey,
            lowerBound,
            upperBound,
            keyType,
            limit,
            reverse,
        },
        filterFn,
        sort,
    ) {
        const mCode = code || this.contractAccount;
        const mScope = scope || this.contractAccount;

        return this.rpc.getTableRows(
            {
                code: mCode,
                scope: mScope,
                table,
                indexPosition,
                tableKey,
                lowerBound,
                upperBound,
                keyType,
                limit,
                reverse,
            },
            filterFn,
            sort,
        );
    }

    async getOneTableRow(
        {
            code, scope, table, indexPosition, tableKey, lowerBound, upperBound, keyType, reverse,
        },
        filterFn,
    ) {
        const mCode = code || this.contractAccount;
        const mScope = scope || this.contractAccount;

        return this.rpc.getOneTableRow(
            {
                json: true,
                code: mCode,
                scope: mScope,
                table,
                indexPosition,
                //tableKey,
                lowerBound,
                upperBound,
                keyType,
                reverse,
            },
            filterFn,
        );
    }
}

export default BaseEosApi;
