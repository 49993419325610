/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useContext, useState, createRef } from "react";
import { ProjectContext } from '../context/projectContext'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, RadioGroup, FormControlLabel, Radio, TextField, IconButton } from '@material-ui/core';
import CardItem from "../components/open/cardItem";
import { Clear } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
    main: {
        background: 'black',
        height: '92vh',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            // maxHeight: '94vh',
            height: '93vh',
        },
        overflow: 'hidden',
        paddingTop: 20
    },
    options: {
        padding: 10
        // background: 'blue',
        // flex: 3,
    },
    contentCards: {
        // background: 'yellow',
        padding: 10,
        background: 'black'
        // flex: 6,
    },
    cardList: {
        width: '100%',
        // background: 'orange',
        height: '75vh',
        overflow: 'auto',
        overflowX: 'hidden',
        [theme.breakpoints.down('sm')]: {
            // maxHeight: '94vh',
            height: '60vh',
        },
    },
    optionRarityLabel: {
        color: 'white'
    },
    optionRarity: {
        color: 'white'
    },
    rarityTitle: {
        color: 'white',
        fontSize: '1.5rem',
        margin: '0px',
        fontWeight: '600'
    },
    containSearch: {
      padding: 5,
      transition: 'all 2s !important',
      transitionDelay: '2s !important',
    },
    cleanIcon: {
        color: 'white'
    },
    searchInput: {
        flexGrow: 1,
        transition: 'all 2s !important',
        transitionDelay: '2s !important',
    },
    radioGroup: {
        position: 'sticky',
        top: 0,
    }
}))

const CssTextField = withStyles({
    root: {
      'color': {
        color: 'white'
      },
      '& input': {
        color: 'white',
      },
      '& label': {
        color: 'white',
      },
      '& label.Mui-focused': {
        color: 'white',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'white',
          color: 'white',
        },
        '&:hover fieldset': {
          borderColor: 'white',
          color: 'white',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
          color: 'white',
        },
      },
    },
  })(TextField);

function Catalog () {
    const { templates, configs, projectApi } = useContext(ProjectContext)
    const [templateConfigs, setTemplateConfigs] = templates
    const [projectConfigs, setProjectConfigs] = configs
    const classes = useStyles()
    const myScroll = createRef()   // Create a ref object 


    const [optionsRarities, setOptionsRarities] = useState(undefined)
    const [selectedRarity, setSelectedRarity] = useState('all')
    const [searchByName, setSearchByName] = useState('')

    useEffect (() => {
        // console.log('Effect Catalog')
        // loadTemplate()
        if (!projectConfigs) {
            fetchConfigs()
        }
        if (!templateConfigs) {
            getTemplates()
        }
        getRarities()
    }, [projectConfigs])

    useEffect(() => {
        // console.log('effect search')
        myScroll.current.scrollTo(0, 0);
    }, [searchByName, selectedRarity])

    const fetchConfigs = async () => {
        const configs = await projectApi.getProjectConfigs()
        // setConfigs(configs)
        setProjectConfigs(configs)
    }

    const getTemplates = async () => {
        const response = await projectApi.getTemplates()
        // setConfigs(configs)
        setTemplateConfigs(response)
    }

    const handlerSearchByName = (event) => {
        setSearchByName(event.target.value)
    }

    const getRarities = () => {
        if (!projectConfigs) return
        const items = Object.entries(projectConfigs)
        const rarities = [
          [
              "All",
              {
                content: 'All'
              }
          ]
        ]
        // console.log(projectConfigs, items)
        items.forEach(i => {
            // console.log('i', i)
            if (i[0].toLowerCase().includes('rarity')) {
                rarities.push(i)
            }
        })

        setOptionsRarities(rarities)
        // console.log('rarities', rarities)
        // return rarities.map(v => {
        //     return (
        //     <Grid item md sm={4} xs={12} key={v[0]}>
        //         <img alt="card" className={classes.imageCardRarities} src={getImageUrl(v[1].input)} />
        //         <p className={classes.labelCardRarities}>{v[1].content}</p>
        //     </Grid>
        //     )
        // })
    }

    const handleChangeRarity = (event) => {
        // console.log('handleChangeRarity', event.target.value)
        setSelectedRarity(event.target.value)
    }

    const renderOptionsRarities = () => {
        return optionsRarities ?
        <RadioGroup className={classes.radioGroup} aria-label="gender" name="gender1" value={selectedRarity} onChange={handleChangeRarity}>
            <Grid container>
            {
                optionsRarities.map(rarity => {
                    return (
                        <Grid item xs={3} sm={6} md={12} key={rarity[1].content}>
                            <FormControlLabel className={classes.optionRarity} value={rarity[1].content.toLowerCase()} control={<Radio color="primary" className={classes.optionRarity} />} label={rarity[1].content} />
                        </Grid>
                    )
                })
            }
            </Grid>
        </RadioGroup> : null
    }

    const renderTemplates = () => {
        // console.log('templateConfigs', templateConfigs)
        if (!templateConfigs) return
        let templatesFiltered = Object.entries(templateConfigs.templates).map(v => v[1])
        if (selectedRarity !== 'all') {
            templatesFiltered = templatesFiltered.filter( t => {
                const rarity = t.immutableData.rarity.toLowerCase()
                // console.log(rarity, selectedRarity)
                return rarity.includes(selectedRarity)
            })
        }
        if (searchByName) {
            templatesFiltered = templatesFiltered.filter(template => {
                return template.immutableData.name.toLowerCase().includes(searchByName.toLowerCase())
            })
        }

        return (
            templatesFiltered.map(template => {
                const card = {
                    template
                }
                // console.log('card', card)
                return (<Grid item xs={6} sm={4} md={3} lg={3} key={card.template.templateId}>
                    <CardItem card={card} />
                </Grid>)
            })
        )
    }

    return (
        <Fragment>
            <Grid container className={classes.main}>
                <Grid className={classes.options} item xs={12} md={3} lg={2}>
                    <p className={classes.rarityTitle}>Rarity</p>
                    { renderOptionsRarities() }
                </Grid>
                <Grid className={classes.contentCards} item xs={12} md={9} lg={10}>
                    <Grid className={classes.containSearch} container>
                        <CssTextField className={classes.searchInput} label="Search by name" variant="outlined" color="primary" value={searchByName} onChange={(e) => handlerSearchByName(e)} />
                        {searchByName ?
                        <IconButton
                            aria-label="toggle password visibility"
                            color="primary"
                            className={classes.cleanIcon}
                            onClick={() => setSearchByName('')}
                            >
                             <Clear />
                        </IconButton>
                        : null }
                    </Grid>
                    <Grid container className={classes.cardList} ref={myScroll} alignItems="flex-end">
                        {renderTemplates()}
                    </Grid>
                </Grid>
            </Grid>
        </Fragment>
    )
}

export default Catalog