import { useState, useContext, useEffect, Fragment } from 'react';
import { Grid, Button, Menu, MenuItem, CircularProgress, Dialog, AppBar,Toolbar, Typography, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PackageView from './packageItem';
// import BaseEosApi from '../../services/BaseEosApi';
import PackagesApi from '../../services/PackagesApi';
import { UALContext } from 'ual-reactjs-renderer';
import SkeletonPackageItem from './skeletonPackageItem';
// import ProjectApi from '../../services/ProjectApi';
// import image from '../../images/standard.png';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Clear from '@material-ui/icons/Clear';
import OpeningVideo from './openingVideo';
import {Close} from '@material-ui/icons';
import { ProjectContext } from '../../context/projectContext';
import PackageOpenedList from './packageOpenedList';
// import ClaimButton from './claimButton';

const useStyles = makeStyles((theme) => ({
    containerList: {
        background: 'black',
        flexGrow: 1,
        minHeight: '90vh',
        // paddingLeft: '5%',
        // paddingRight: '5%',
        // maxHeight: '85vh',
        overflow: 'scroll',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            // maxHeight: '94vh',
            height: '90vh',
        },
        paddingLeft: 70,
        paddingRight: 70,
        paddingTop: 20
    },
    contItem: {
        // padding: '10px'
        paddingLeft: '5px',
        paddingRight: '5px',
        paddingBottom: '5px'
    },
    containerLabelEmpty: {
        // background: 'gray'
    },
    emptyLabel: {
        color: 'white',
        fontSize: '1rem',
        fontWeight: '400',
        textAlign: 'center',
        width: '30%'
    },
    emptyImage: {
        width: '10%'
    },
    containerFilter: {
    //   background: 'green',
      height: '80px',
      width: '100%',
      position: 'sticky',
      top: 0,
      zIndex: '15',
      padding: '10px'
    },
    filterBy: {
        // height: '50px',
        // width: '200px'
        // background: 'black',
        // color: 'white'
        border: 'solid 2px',
        borderColor: 'white',
        color: 'white',
        background: 'black',
        textTransform: 'none'
    },
    clearIcon: {
        cursor: 'pointer',
        marginLeft: '10px'
    },
    loadingAnimation: {
        color: 'green',
        padding: '5px'
    },
    btnLoadMore: {
        border: 'solid 2px',
        borderColor: 'white',
        color: 'white !important',
        background: 'black',
        marginTop: '50px',
        marginBottom: '50px'
    },
    scroll: {
        width: '100%',
        // maxHeight: '80vh'
        // margin: '-10px',
        // padding: '-10px'
    },
    emptyContainer: {
        // background: 'blue',
        height: '38vh'
    }
}));

function PackagesList () {
    const classes = useStyles();
    const ual = useContext(UALContext);
    const { templates, projectApi, domain, showErrorNotification, configs } = useContext(ProjectContext)
    const [packages, setPackages] = useState([])
    // const [templates, setTemplates] = useState(undefined)
    const [loading, setLoading] = useState(true)
    const [filterBy, setFilterBy] = useState(undefined)
    const [showDialog, setShowDialog] = useState(false)
    const [showingVideo, setShowingVideo] = useState(false)
    const [packageSelected, setPackageSelected] = useState(false)
    const [cardsObtained, setCardsObtained] = useState([])
    const [anchorElFilter, setAnchorElFilter] = useState(null);
    const [params, setParams] = useState({
        isMore: true,
        limit: 10,
        filterBy: undefined,
        collectionName: undefined,
        schemaName: undefined,
        // schemaName: 'card.packs3',
        page: 1
    })

    const [templateConfigs, setTemplateConfigs] = templates
    const [domainConfigs] = domain
    const packagesApi = new PackagesApi(ual.activeUser);
    const [projectConfigs] = configs
    // const projectApi = new ProjectApi({});


    useEffect(() => {
        async function getTemplates (){
            const response = await projectApi.getTemplates()
            // setTemplates(response)
            setTemplateConfigs(response)

        }
        // async function getPackages (){
        //     const response = await packagesApi.getPackagesByUser({ accountName: ual.activeUser.accountName })
        //     setPackages(response.rows)
        // }
        async function setUp () {
            if (packages.length === 0) {
                setLoading(true)
                if (!templateConfigs) {
                    await getTemplates()
                    setUpParams()
                } else {
                    await onLoadMore()
                }

            }
            // setParams({
            //     ...params,
            //     loading: false
            // })
        }
        try {
            // setUpParams()
            setUp()
            // setShowingVideo(true)
        } catch (e) {
            console.error('An error occurred while trying to get templates and Packages: ', e)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const setUpParams = () => {
        // console.log('setUpParams', templateConfigs)
        if (!templateConfigs || !templateConfigs.packTemplates) return
        const schemaName = Object.entries(templateConfigs.packTemplates)
        // console.log('schemaName',  schemaName[1][1].schemaName)
        setParams({
            ...params,
            schemaName: schemaName[1][1].schemaName
        })
    }

    useEffect(() => {
        // resetPagination()
        if (templateConfigs) {
            setUpParams()
            onLoadMore()
        }
    // eslint-disable-next-line
    }, [templateConfigs])

    useEffect(() => {
        // resetPagination()
        console.log('useEffect filterBy', filterBy)
        if (templateConfigs) {
            if (filterBy && filterBy.notClaimed) {
                onLoadPacksNotClaimed()
            } else {
                onLoadMore()
            }
        }
        // console.log('')
    // eslint-disable-next-line
    }, [filterBy])

    useEffect(() => {
        // resetPagination()
        console.log('useEffect showingVideo', showingVideo)
        if (!showingVideo) {
            setPackageSelected(undefined)
            console.log('setPackageSelected success to undefined')
        }
    // eslint-disable-next-line
    }, [showingVideo])

    const onLoadMore = async () => {
        try {
            setLoading(true)
            const schemaName = Object.entries(templateConfigs.packTemplates)
            const response = await packagesApi.getPackagesByUserApi({
                ...params,
                owner: ual.activeUser.accountName,
                schemaName: schemaName[1][1].schemaName,
                collectionName: domainConfigs.collection,
                filterBy
            })
            // console.log('onLoadMore', response.data)
            setPackages(packages.concat(response.data))
            setParams({
                ...params,
                page: params.page + 1,
                isMore: !!(response.data.length === 10 )
            })
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    const onLoadPacksNotClaimed = async () => {
        try {
            setLoading(true)
            // get not claimed packs
            // const { rows: packsUnclaimed } = await packagesApi.getNotClaimedPackages({
            const packsUnclaimed = await packagesApi.getNotClaimedPackages({
                unboxer: ual.activeUser.accountName,
            })

            console.log('DEBUG packsUnclaimed', packsUnclaimed)
            // Get lower bound and upper bound for packs query
            let min = Number.POSITIVE_INFINITY
            let max = Number.NEGATIVE_INFINITY
            packsUnclaimed.forEach(pack => {
                // console.log('getting min', pack, min)
                min = Math.min(min, pack.pack_id)
                max = Math.max(max, pack.pack_id)
            });
            console.log('min and max', min, max)

            if (packsUnclaimed.length === 0) {
                setPackages([])
                return
            }
            // min = 3250
            // max = 3257
            // Get template info for unclaimed packs
            // const { rows: packsUnclaimedTemplates = await packagesApi.getNotClaimPackagesTemplates({ min, max })
            // const packsUnclaimedTemplates = await packagesApi.getNotClaimPackagesTemplates({ min, max })
            // console.log('DEBUG packsUnclaimedTemplates', packsUnclaimedTemplates)
            // Map packsNotClaimed
            const templatesEntries = Object.entries(templateConfigs.packTemplates)
            console.log('templatesEntries', templatesEntries)
            let packsNotClaimedMap = packsUnclaimed.map(pack => {
                // let templateId =  packsUnclaimedTemplates.find(v => v.pack_id === pack.pack_id)
                // templateId = Object.entries(templateConfigs.packTemplates)
                const templateFind = templatesEntries.find( (tmp) => {
                    if (tmp[1].packId === pack.pack_id) {
                        console.log('finding template', tmp, tmp[1])
                        return tmp
                    } else return undefined
                })
                let template
                if (templateFind) {
                    template = templateFind[1]
                }
                // console.log('templateId', templateId)
                console.log('template find', templateFind, template)
                // if (!templateId) return undefined
                // templateId = templateId.pack_template_id
                // const template = templateConfigs.packTemplates[templateId]
                if (!template) return undefined
                return {
                    asset_id: pack.pack_asset_id,
                    collection: {
                        collection_name: domainConfigs.collection
                    },
                    data: {
                        ...template.immutableData
                    },
                    templateId: template.templateId,
                    template: {
                        ...template,
                        template_id: template.templateId
                    },
                    notClaimed: true
                }
            })
            // Filter packs for this collection
            packsNotClaimedMap = packsNotClaimedMap.filter(v => v !== undefined)
            // Set packs to List
            setPackages(packages.concat(packsNotClaimedMap))
            setParams({
                ...params,
                page: params.page + 1,
                isMore: false
            })
        } catch (e) {
            console.error(e)
        } finally {
            setLoading(false)
        }
    }

    const renderList = () => {
         return packages.map(v => {
            const r = Math.random(10)
            const packTemplate = templateConfigs.packTemplates[v.template.template_id]
            if (packTemplate) {
                return (
                    <PackageView key={v.asset_id + r} openPackage={settingSelectedPackage} claimPackage={claimPackage} packageItem={v} packTemplate={packTemplate} />
                )
            } else {
                return null
            }
        })
    }

    const renderSkeletonList = () => {
        return [1, 2, 3, 4, 5, 6, 7, 8, 9].map(v => {
            return (
                <Grid key={v} className={classes.contItem} item xs={12} sm={6} md={4} lg={4}>
                    <SkeletonPackageItem key={v} styles={{padding: 20, margin: 20}} />
                </Grid>
            )
        })
    }

    const handleClick = (event) => {
        setAnchorElFilter(event.currentTarget);
    };


    const handleClose = async (v) => {
        // console.log('selected', v)
        setAnchorElFilter(null);
        if (!v) return
        await setPackages([])
        await setParams({
            ...params,
            page: 1,
            isMore: true
        })
        setFilterBy(v)
    };


    const renderListEmpty = () => {
        // console.log('mes', filterBy)
        const message = (filterBy && filterBy.notClaimed) ? "You don't have unclaimed packs." : "You do not have any packs."
        return (
        <Grid container direction="column" justifyContent="center">
            <Grid container alignItems="center" justifyContent="center">
                <img src={projectConfigs.noPacksImage.input} className={classes.emptyImage} alt="emptyImage" />
            </Grid>
            <Grid container alignItems="center" justifyContent="center">
                <p className={classes.emptyLabel}>
                    {message}
                </p>
            </Grid>
            {/* <Button variant="contained">
                Buy Packages
            </Button> */}
        </Grid>
        )
    }

    const renderOptionsFilter = () => {
        // Not claimed package
        const optionsFilter = templateConfigs.packTemplates
        const notClaimedOption = {
            immutableData: {
                name: 'Unclaimed Pack'
            },
            templateId: 'notClaimed',
            notClaimed: true
        }
        optionsFilter.notClaimed = notClaimedOption
        // console.log('renderOptionsFilter',optionsFilter, Object.entries(optionsFilter))
        return Object.entries(optionsFilter).map(v => {
            const option = v[1]
            // console.log('entrie', option, filterBy)
            return (
                <MenuItem
                    onClick={() => handleClose(option)}
                    key={option.templateId}
                    selected={(filterBy && (option.templateId === filterBy.templateId))}
                    disabled={(filterBy && (option.templateId === filterBy.templateId))}
                >
                    {option.immutableData.name}s
                </MenuItem>
            )
        })
        // return options
    }

    const renderDialogs = () => {
        return <div>
            <Dialog fullScreen open={showDialog} onClose={closePackageDialog}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={closePackageDialog} aria-label="close">
                            <Close />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            Cards Obtained
                        </Typography>
                        {/* <Button autoFocus color="inherit" onClick={handleCloseDialog}>
                        save
                        </Button> */}
                    </Toolbar>
                </AppBar>
                <div style={{marginTop: 60}}>
                    <PackageOpenedList cards={cardsObtained} />
                </div>
            </Dialog>
            { packageSelected &&
                <Fragment>
                    <Dialog fullScreen open={showingVideo}>
                        <OpeningVideo urlVideo={packageSelected ? packageSelected.urlVideo : undefined} videoFinished={showPackageOpened}/>
                    </Dialog>
                    {/* <Dialog open={showingVideo}>
                        <ClaimButton packageItem/>
                    </Dialog> */}
                </Fragment>
            }
        </div>
    }

    /**
     * Closed Opened Package Details
     */
    const closePackageDialog = () => {
        setShowDialog(false);
        setShowingVideo(false)
        // setPackageSelected(undefined);
        setCardsObtained([])
    };

    /**
     * Show Animation while package is opened
     */
    const showOpeningVideo = () => {
        setLoading(true)
        setShowingVideo(true)
        setLoading(false)
        console.log('showOpeningVideo success to true')
    }

    const getOpeningVideo = (templateId) => {
        try {
            return templateConfigs.packTemplates[templateId].openingVideo
        } catch (e) {
            console.error(e)
            return undefined
        }
    }

    useEffect(() => {
        // resetPagination()
        console.log('useEffect packageSelected', packageSelected)
        if (packageSelected) {
            openPackage()
            // setPackageSelected(undefined)
            // console.log('setPackageSelected success to undefined')
        }
    // eslint-disable-next-line
    }, [packageSelected])

    const settingSelectedPackage = async (packageItem) => {
        await setPackageSelected({
            ...packageItem,
            urlVideo: getOpeningVideo(packageItem.template.template_id)
        })
            // console.log('selectedPackageSetted', packageSelected)
    }


    const claimPackage = async (packageItem) => {
        console.log('claimPackage', claimPackage)
        try {
            // console.log('params', packageSelected)
            // setTryingCount(1)
            // let count = 0
            console.log('showPackageOpened inited')
            let rolls = await getRolls(packageItem.asset_id, 2)
            console.log('rolls got', rolls)
            console.log('setShowingVideo success to false')
            const response = await packagesApi.claimPackage({
                rollIds: rolls,
                assetId: packageItem.asset_id
            })
            console.log('claimPackage response', response)
            setShowingVideo(false)
            console.log('claimPackage success')
            // console.log('claimResponse', claimResponse)
            const newPack = packages.filter(v => v.asset_id !== packageItem.asset_id)
            setPackages(newPack)
            setShowDialog(true)
            console.log('Set show dialog success to Open')
            console.log('Replace list success, finishing showPackageOpened')
        } catch (e) {
            setShowingVideo(false)
            console.error('An error ocurred while trying to claim your package', e)
            showErrorNotification('An error ocurred while trying to claim your package, please go to "Unclaimed packs" section in the filter to claim the package, if the error persist please await some minutes and try again.')

            showErrorNotification(e.message || e)
            // showErrorNotification(e.message || e)
        }
    }
    /**
     * Open package
     */
    const openPackage = async () => {
        const packageItem = packageSelected
        console.log('openingPackage', packageItem)
        try {
            // await setPackageSelected({
            //     ...packageItem,
            //     urlVideo: getOpeningVideo(packageItem.template.template_id)
            // })
            // console.log('selectedPackageSetted', packageSelected)

            if (!packageItem.notClaimed) {
                console.log('Not unclaimed pack', packageItem)
                const response = await packagesApi.unboxPackage({
                    from: packageItem.owner,
                    assetId: packageItem.asset_id
                })
                console.log('unboxPackage response', response)
                showOpeningVideo(packageItem)
                const newPack = packages.filter(v => v.asset_id !== packageItem.asset_id)
                await setPackages(newPack)
                console.log('package opened was removed', packageItem.asset_id)
            } else {
                showOpeningVideo(packageItem)
            }
            // console.log('Package unboxed', rUnbox)
            console.log('showOpeningVideo was triggered')
        } catch (e) {
            showErrorNotification(e.message || e)
            setPackageSelected(undefined)
            console.error('An error ocurred while try to open Package ', e)
        }
    }

    /**
    * Show Opened Package Details
    */
    const showPackageOpened = async () => {
        try {
            // console.log('params', packageSelected)
            // setTryingCount(1)
            // let count = 0
            console.log('showPackageOpened inited')
            let rolls = await getRolls(packageSelected.asset_id, 2)
            console.log('rolls got', rolls)
            console.log('setShowingVideo success to false')
            const response = await packagesApi.claimPackage({
                rollIds: rolls,
                assetId: packageSelected.asset_id
            })
            console.log('claimPackage response', response)
            setShowingVideo(false)
            console.log('claimPackage success')
            // console.log('claimResponse', claimResponse)
            const newPack = packages.filter(v => v.asset_id !== packageSelected.asset_id)
            setPackages(newPack)
            setShowDialog(true)
            console.log('Set show dialog success to Open')
            console.log('Replace list success, finishing showPackageOpened')
        } catch (e) {
            setShowingVideo(false)
            console.error('An error ocurred while trying to claim your package', e)
            showErrorNotification('An error ocurred while trying to claim your package, please go to "Unclaimed packs" section in the filter to claim the package, if the error persist please await some minutes and try again.')

            showErrorNotification(e.message || e)
            // showErrorNotification(e.message || e)
        }
    }

    const getRolls = async (assetIds, retries) => {
        return new Promise(async (resolve, reject) => {
            console.log('getRolls inited')
            const response = await packagesApi.fetchRolls(assetIds)
            // console.log('response rolls', response, assetIds)
            const rolls = response.rows.map(v => {
                return v.origin_roll_id
            })
            if (rolls.length === 0 && retries < 6) {
                console.log('retry rolls', retries)
                try {
                    setTimeout(async () => {
                        try {
                            return await getRolls(assetIds, retries + 1)
                        } catch (e) {
                            setShowingVideo(false)
                            showErrorNotification(e.message || e)
                            // throw new Error(e)
                        }
                    }, 2000)
                } catch (e) {
                    throw new Error(e)
                }
            } else if (rolls.length > 0) {
                console.log('setCardsObtained', templateConfigs)
                await setCardsObtained(response.rows.map(v => {
                    return {
                        ...v,
                        template: templateConfigs.templates[v.template_id]
                    }
                }))
                console.log('resolve rolls', rolls)
                resolve(rolls)
            } else if (rolls.length === 0 && retries === 6){
                console.error('Error: many retries')
                reject({
                    // message: 'An error ocurred while trying to claim your package, please go to "Unclaimed packs" section in the filter to claim the package.'
                    // message: 'It seems the atomicpacksx contract is bussy at this moment. Please, wait a few minutes and go to "unclaimed" packs section to try again.'
                    message: 'We are experiencing a higher load than usual on the Atomic Hub server. Please wait a few minutes and filter your "unclaimed" packs to try again.'
                })
            }
        })
    }

    const clearFilter = () => {
        setPackages([])
        setParams({
            ...params,
            page: 1,
            isMore: true
        })
        setFilterBy(undefined)
    }

    const render = () => {
        // console.log('render', params)
        if ((loading && !templateConfigs) || (loading && packages.length === 0)) {
            // console.log('render Skeleton')
            return renderSkeletonList()
        } else {
            if (packages.length > 0 && templateConfigs) {
                // console.log('render list', packages)
                return (
                    <Grid container>
                        <Grid container className={classes.scroll}>
                            {renderList()}
                        </Grid>
                            {params.isMore ?
                                <Grid container direction="row" justifyContent="center">
                                    <Grid item xs={12} sm={5}>
                                        <Button className={classes.btnLoadMore} variant="contained" fullWidth onClick={() => onLoadMore()} disabled={!!loading}>
                                            {loading ? 'Loading...' : 'Load More'}
                                            {/* <CircularProgress size={30} className={classes.loadingAnimation} /> */}
                                            {loading ? <CircularProgress size={25} className={classes.loadingAnimation} />: null}
                                        </Button>
                                    </Grid>
                                </Grid> : null
                            }
                    </Grid>
                )
            } else {
                // console.log('render empty', params, templates, packages)
                return <Grid container className={classes.emptyContainer}>
                    {renderListEmpty()}
                </Grid>
            }
        }
    }

    return (
    <Grid className={classes.containerList} container>
        <Grid container>
        {templateConfigs ?
         <Grid container direction="row" justifyContent="flex-end" alignItems="center" className={classes.containerFilter}>
                            <Button  endIcon={<ExpandMore />} variant="outlined" className={classes.filterBy} aria-controls="simple-menu" aria-haspopup="true" onClick={(v) => handleClick(v)}>
                                {
                                    (filterBy) ? filterBy.immutableData.name : 'Filter By'
                                }
                            </Button>
                            {(filterBy) ?
                                <Clear color="primary" className={classes.clearIcon} onClick={() => clearFilter()}/> : null
                            }
                            <Menu
                                id="lock-menu"
                                anchorEl={anchorElFilter}
                                open={Boolean(anchorElFilter)}
                                keepMounted
                                onClose={() => handleClose()}

                            >
                                {
                                    renderOptionsFilter()
                                }
                            </Menu>
            </Grid> : null
        }
        {renderDialogs()}
        {
            render()
        }
        </Grid>
    </Grid>
    )
}

export default PackagesList