import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#F0F0F0',
      main: '#F0F0F0',
      dark: '#383838',
      contrastText: '#000',
      contrastTextLight: '#bebebe'
    },
    secondary: {
      light: '#00DCD2',
      main: '#00DCD2',
      dark: '#00DCD2',
      contrastText: '#000',
    },
    error: {
      light: '#f33',
      main: '#f33',
      dark: '#f33',
      contrastText: '#000',
    },
    dark: {
      light: '#33eb91',
      main: '#00e676',
      dark: '#00a152',
      contrastText: '#fff',
    },
    success: {
      light: '#33eb91',
      main: '#00e676',
      dark: '#00a152',
      contrastText: '#fff',
    }
  }
});

export default theme
