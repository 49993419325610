/* eslint-disable no-loop-func */
import BaseEosApi from "./BaseEosApi";
import RequestApi from "./RequestApi";
const {
    REACT_APP_ATOMIC_API_URL: ATOMIC_API_URL,
    REACT_APP_ATOMIC_ASSET_CONTRACT: ATOMIC_ASSET_CONTRACT,
    REACT_APP_ATOMIC_PACKS_CONTRACT: ATOMIC_PACKS_CONTRACT,
} = process.env;
class PackagesApi extends BaseEosApi {
    constructor(activeUser) {
        super(activeUser, ATOMIC_ASSET_CONTRACT)
        this.api = new RequestApi(ATOMIC_API_URL)
        this.atomicPacks = new BaseEosApi(activeUser, ATOMIC_PACKS_CONTRACT)
    }

    getPackagesByUser({ accountName, nextKey, limit }){
        // console.log('getPackagesByUser', { accountName, nextKey, limit })
        return this.getTableRows({
            table: 'assets',
            tableKey: 'asset_id',
            keyType: 'i64',
            scope: accountName,
            lowerBound: nextKey,
            limit
        })
    }

    async getPackagesByUserApi({ owner, collectionName, schemaName, templateId, limit, page, filterBy }) {
        // console.log('filterBy', filterBy)
        const { data } = await this.api.get('/v1/assets', {
            owner,
            collection_name: collectionName,
            schema_name: schemaName,
            limit,
            page,
            template_id: filterBy ? filterBy.templateId : undefined

        })
        return data
    }

    async getFullCatalog({ owner, collectionName, schemaName, templateId, limit, page, filterBy }) {
        // console.log('filterBy', filterBy)
        const { data } = await this.api.get('/v1/assets', {
            owner,
            collection_name: collectionName,
            schema_name: schemaName,
            limit,
            page,
            template_id: filterBy ? filterBy.templateId : undefined

        })
        return data
    }

    async unboxPackage({from, assetId}) {
        // console.log('unboxPackage', from, assetId)
        const actions = [
            await this._formatAction({
                name: 'transfer',
                data: {
                    from,
                    memo: 'unbox',
                    to: ATOMIC_PACKS_CONTRACT,
                    asset_ids: [Number.parseInt(assetId)]
                }
            })
        ]
        return this.transactFull(actions)
    }

    fetchRolls (assetId) {
        return this.atomicPacks.getTableRows({
            table: 'unboxassets',
            scope: assetId,
            limit: 150,
            keyType: 'i64'
        })
    }

    async getNotClaimedPackages ({ unboxer }) {
        let isMore = true
        let notClaimedPackages = []
        let lowerBound = unboxer
        // let nextKey
        while (isMore) {
            // debugger
            const { rows } = await this.atomicPacks.getTableRows({
                table: 'unboxpacks',
                scope: ATOMIC_PACKS_CONTRACT,
                limit: 400,
                indexPosition: 2,
                keyType: 'i64',
                lowerBound,
                upperBound: unboxer,
                reverse: true
                // nextKey: nextKey
            })

            // rows.forEach(unboxPack => {
            //     if (unboxPack.unboxer === unboxer) {
            //         notClaimedPackages.push(unboxPack)
            //     } else {
            //         isMore = false
            //         return
            //     }
            // })
            notClaimedPackages = notClaimedPackages.concat(rows)
            isMore = false
            // isMore = false
        }
        return notClaimedPackages
    }

    async getNotClaimPackagesTemplates ({min, max}) {
        let isMore = true
        let packsTemplates = []
        let lowerBound = min
        let upperBound = max
        // let nextKey
        while (isMore) {
            debugger
            const { rows, more, next_key } = await this.atomicPacks.getTableRows({
                table: 'packs',
                scope: ATOMIC_PACKS_CONTRACT,
                limit: 300,
                indexPosition: 1,
                keyType: 'i64',
                lowerBound,
                upperBound,
                // nextKey
            })

            rows.forEach(packTemplate => {
                if (packTemplate.pack_id >= lowerBound && packTemplate.pack_id <= upperBound) {
                    packsTemplates.push(packTemplate)
                } else {
                    isMore = false
                    return
                }
            })
            lowerBound = next_key
            isMore = more
            // isMore = false
        }
        return packsTemplates
        // return this.atomicPacks.getTableRows({
        //     table: 'packs',
        //     scope: ATOMIC_PACKS_CONTRACT,
        //     limit: 400,
        //     indexPosition: 1,
        //     keyType: 'i64',
        //     lowerBound: min,
        //     upperBound: max,
        //     reverse: true
        // })
    }

    async claimPackage({rollIds, assetId}) {
        const actions = [
            await this._formatAction({
                account: ATOMIC_PACKS_CONTRACT,
                name: 'claimunboxed',
                data: {
                    origin_roll_ids: rollIds,
                    pack_asset_id: Number.parseInt(assetId)
                }
            })
        ]
        return this.transactFull(actions)
    }
}

export default PackagesApi