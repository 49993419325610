import { Fragment, useContext } from "react";
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { UALContext } from 'ual-reactjs-renderer';

const useStyles = makeStyles((theme) => ({
    signInLabelTitle: {
        fontSize: '2em',
        fontWeight: 'bold',
        marginBlockStart: '0.67em',
        marginBlockEnd: '0.67em',
        marginBottom: '10px !important',
        color: 'white',
        textAlign: 'center'
    },
    signInLabel: {
        fontSize: '1rem',
        fontWeight: 600,
        margin: '2px'
    },
    containerLogin: {
        // background: 'green',
        height: '88vh',
        justifyContent: 'center',
        // width: '50%'
    },
    containerButtons: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            // bottom: 180,
            width: '80%',
        },
    },
    separator: {
        backgroundColor: 'gray',
        height: '2px',
        marginBottom: '15px'
    },
    containerAlternative: {
        // position: 'relative',
        marginTop: 40,
        // bottom: 300,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            // bottom: 180,
            width: '100%',
            marginTop: 20,
        },
        // background: 'blue'
    },
    labelDesc: {
        color: 'white',
        textAlign: 'center',
        fontSize: '1.6em',
        margin: 0,
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.3em',
            padding: 18
        },
    },
    link: {
      textDecorationColor: 'white',
      color: 'white !important',
      margin: 0,
    }
}))

function LoginView () {
    const ual = useContext(UALContext);
    const classes = useStyles();

    const onPressLogin = async () => {
        localStorage.setItem('autoLogin', true);
        ual.showModal();
    };

    return (
        <Fragment>
            <Grid container alignItems="center" className={classes.containerLogin}>
                <div className={classes.containerButtons}>
                    <p className={classes.signInLabelTitle}>
                        To continue, please login:
                    </p>
                    <div className={classes.separator} />
                    <Button color="inherit" variant="contained" onClick={() => onPressLogin()}>
                        <p className={classes.signInLabel}>
                            SIGN UP / SIGN IN
                        </p>
                    </Button>
                        <div className={classes.containerAlternative}>
                            <Grid direction="row" container justifyContent="center">
                                    <p className={classes.labelDesc}>If you have any trouble opening your pack, as an alternative please go <a className={classes.link} rel="noreferrer" href="https://wax.atomichub.io/unbox/3254+3255" target="_blank">here</a></p>
                            </Grid>
                        </div>
                </div>
            </Grid>
        </Fragment>
    )
}

export default LoginView