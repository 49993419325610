import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UALProvider, withUAL } from 'ual-reactjs-renderer';
// import { Scatter } from 'ual-scatter';
import { Anchor } from 'ual-anchor';
import { Wax } from '@eosdacio/ual-wax'
// import { Wax } from './dependences/ual-wax-master/src/Wax'
// import { Sqrl } from '@smontero/ual-sqrl';
import ProjectProvider from './context/projectContext';

const {
  REACT_APP_EOS_APP_NAME: appName,
  REACT_APP_EOS_HTTP_ENDPOINT_PROTOCOL: protocol,
  REACT_APP_EOS_HTTP_ENDPOINT_HOST: host,
  REACT_APP_EOS_HTTP_ENDPOINT_PORT: port,
  REACT_APP_EOS_CHAIN_ID: chainId
} = process.env;


const network = {
  chainId,
  rpcEndpoints: [
      {
          protocol,
          host,
          port,
      },
  ],
};

const UALApp = withUAL(App);
UALApp.displayName = appName;

const anchor = new Anchor([network], { appName });
const wax = new Wax([network]);

ReactDOM.render(
  <React.StrictMode>
        <ProjectProvider>
          <UALProvider
          chains={[network]}
          authenticators={[
              // scatter,
              anchor,
              wax,
              // sqrl
          ]}
          appName={appName}
      >
            <UALApp />
      </UALProvider>
    </ProjectProvider>,
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
