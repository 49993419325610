import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Fragment } from 'react';
import CardItem from './cardItem';
import Zoom from '@material-ui/core/Zoom';
// import Grow from '@material-ui/core/Grow';

// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Switch from '@material-ui/core/Switch';
import Paper from "@material-ui/core/Paper";


const useStyles = makeStyles((theme) => ({
    containerList: {
        paddingLeft: '10%',
        paddingRight: '10%',
        paddingTop: '2%',
        background: 'black',
        width: '100%',
        // height: '95vh',
        // overflow: 'auto',
    },
    paper: {
        width: 'inherit',
        background: 'black',
        margin: 10

    }
}))

function PackageOpenedList (props) {
    const {cards} = props
    const classes = useStyles();
    // const [checked, setChecked] = useState(false);

    // const handleChange = () => {
    //     setChecked((prev) => !prev);
    // };

    const renderCards = () => {
        const itemPositionTime = 400
        let position = 1
        return cards.map(card => {
            const ms = `${itemPositionTime * position}ms`
            // console.log('itemPositionTime', position, ms)
            position++
            return (
            <Grid key={ms} className={classes.contItem} item xs={12} sm={4} md={3} lg={3}>
                <Zoom
                    in={true}
                    style={{ transitionDelay: true ? ms : '0ms' }}
                >
                    <Paper variant="outlined" className={classes.paper}>
                        <CardItem card={card} />
                    </Paper>
                </Zoom>
            </Grid>
            )
        })
    }

    return (
        <Fragment>
            {/* <FormControlLabel
                control={<Switch checked={checked} onChange={handleChange} />}
                label="Show"
            />
            <p>
                { checked ? 'checked' : 'not checked'}
            </p> */}
           <Grid container className={classes.containerList} alignItems="flex-end">
                {
                    renderCards()
                }
            </Grid>
        </Fragment>
    )
}

export default PackageOpenedList