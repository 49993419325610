import { useEffect, useContext } from 'react'
// import { UALContext } from 'ual-reactjs-renderer'
// import { useHistory } from 'react-router-dom'
// import logo from '../logo.svg';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, useMediaQuery } from '@material-ui/core';
// import CatalogImage from '../images/catalog.png';
//  import { webAppConfig } from '../const/webApp';
// import RequestApi from '../services/RequestApi';
// import ProjectApi from '../services/ProjectApi';
import {ProjectContext} from '../context/projectContext';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'white'
  },
  heroImage: {
    width: '100%',
    position: 'absolute',
  },
  containerHeroImage: {
    // position: 'relative',
    background: 'gray',
    height: '900px',
    width: '100%',
    // backgroundImage: `url("${webAppConfig.heroImage.input}")`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginTop: '-100px',
    marginBottom: '-80px',
    paddingBottom: 40
  },
  titleSeries: {
    textAlign: 'center',
    color: 'white',
    fontWeight: 'bold',
    fontSize: 'calc((4.2 - 1.2) * 1.2vw + 1rem)',
    margin: 0
  },
  containerTitleSeries: {
    width: '100%',
    height: '220px',
  },
  containerCardRarities: {
    background: 'black',
    width: '100%',
    paddingBottom: 100,
    paddingTop: 100,
    // marginBottom: '220px'
    // height: '400px'
  },
  titleCardRarities: {
    fontSize: 'calc((1.5 - 1) * 1.2vw + 1rem)',
    color: 'white',
    fontWeight: '400',
    textAlign: 'center',
    width: '100%',
    marginTop: '50px'
  },
  imageCardRarities: {
    width: '100%'
  },
  labelCardRarities: {
    color: 'white',
    textAlign: 'center'
  },
  containerCompleteSet: {
    position: 'relative',
    // background: 'green',
    display: 'block',
    width: '100%',
    height: '800px',
    marginTop: 100,
    // paddingBottom: 100,
  },
  imageCompleteSet: {
    width: '65%',
    position: 'absolute',
    right: '4%',
    top: '8%'
  },
  containerDescCompleteSet: {
    position: 'absolute',
    display: 'inner-block',
    background: 'black',
    width: '36%',
    height: '550px',
    left: '4%',
    top: '15%',
    paddingLeft: '40px',
    paddingRight: '10px'
  },
  containerDescCompleteSetMob: {
    position: 'absolute',
    display: 'inner-block',
    background: 'black',
    width: '80%',
    height: '500px',
    left: '4%',
    top: '15%',
    paddingLeft: '40px',
    paddingRight: '10px'
  },
  containerGetWax: {
    position: 'relative',
    // background: 'green',
    display: 'block',
    width: '100%',
    height: '800px',
    // marginTop: 60,
    // paddingBottom: 100,
  },
  imageGetWax: {
    width: '65%',
    position: 'absolute',
    left: '4%',
    top: '8%'
  },
  containerDescGetWax: {
    position: 'absolute',
    display: 'inner-block',
    background: 'black',
    width: '36%',
    height: '550px',
    right: '4%',
    top: '15%',
    paddingLeft: '40px',
    paddingRight: '10px',
    // paddingTop: 100,
    // paddingBottom: 100,
  },
  containerDescGetWaxMob: {
    position: 'absolute',
    display: 'inner-block',
    background: 'black',
    width: '80%',
    height: '400px',
    right: '0px',
    top: '15%',
    paddingLeft: '20px',
    // paddingRight: '10px'
  },
  titleSection: {
    color: 'white',
    fontSize: 'calc((4.2 - 1) * 1.2vw + 1rem)',
    marginBottom: 0
  },
  descSection: {
    color: 'white',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '2'
  },
  buttonLabel: {
    textTransform: 'none',
    fontSize: '20px'
  },
}));

function Home () {
  const classes = useStyles();
  // const [configs, setConfigs] = new useState(undefined)
  // const googleStorageApi = new RequestApi("https://storage.googleapis.com/");
  const { configs, projectApi } = useContext(ProjectContext)
  const [projectConfigs, setProjectConfigs] = configs
  // const [domainConfigs] = domain
  // console.log('domainConfig', domainConfigs)
  // const projectApi = new ProjectApi({
  //   collection: domainConfigs.collection,
  //   series: domainConfigs.series,
  // })

  const matches = useMediaQuery('(min-width:600px)');

  const fetchConfigs = async () => {
    const configs = await projectApi.getProjectConfigs()
    // setConfigs(configs)
    setProjectConfigs(configs)
  }
  

  useEffect(() => {
    if (!projectConfigs) {
      fetchConfigs()
    }
    // console.log('configs: ', configs)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getImageUrl = (driveUrl) => {
    // const id = driveUrl.replace('https://drive.google.com/file/d/', '').replace('/view?usp=sharing', '')
    // const url = 'https://drive.google.com/uc?export=view&id='+id
    return driveUrl
  }

  const renderRarities = () => {
    const items = Object.entries(projectConfigs)
    const rarities = []
    // console.log(projectConfigs, items)
    items.forEach(i => {
      // console.log('i', i)
      if (i[0].toLowerCase().includes('rarity')) {
        rarities.push(i)
      }
    })
    // console.log('rarities', rarities)
    return rarities.map(v => {
      return (
        <Grid item md sm={4} xs={12} key={v[0]}>
          <img alt="card" className={classes.imageCardRarities} src={getImageUrl(v[1].input)} />
          <p className={classes.labelCardRarities}>{v[1].content}</p>
        </Grid>
      )
    })
  }

  const render = () => {
     return (
      <Grid className={classes.root}>
        <Grid container className={classes.containerHeroImage} style={{ backgroundImage: `url("${getImageUrl(projectConfigs.heroImage.input)}")` }} justifycontent="center" alignContent="flex-end">
          <div className={classes.containerTitleSeries}>
            <p className={classes.titleSeries}>{projectConfigs.collectionName.input} {projectConfigs.series.input}</p>
          </div>
        </Grid>
        <Grid container className={classes.containerCardRarities}>
          <p className={classes.titleCardRarities}>Card Rarities</p>
          <Grid item xs={12}>
            <Grid container justifycontent="center" style={{ padding: '10px'}} spacing={matches ? 2 : 0}>
                { renderRarities() }
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.containerCompleteSet}>
            <img alt="imageSection" className={classes.imageCompleteSet} src={getImageUrl(projectConfigs.completeYourSet.input)} />
            <div className={matches ? classes.containerDescCompleteSet : classes.containerDescCompleteSetMob}>
              <h2 className={classes.titleSection}>{projectConfigs.completeYourSetTitle.input}</h2>
              <p className={classes.descSection}>{projectConfigs.completeYourSet.content}</p>
              {/* <Button color="primary" variant="contained" size="large" classes={{ label: classes.buttonLabel}}>
                Buy Now
              </Button> */}
            </div>
        </div>
        <div className={classes.containerGetWax}>
            <img alt="imageSection" className={classes.imageGetWax} src={getImageUrl(projectConfigs.waxWallet.input)} />
            <div className={matches ? classes.containerDescGetWax : classes.containerDescGetWaxMob }>
              <h2 className={classes.titleSection}>{projectConfigs.waxWalletTitle.input}</h2>
              <p className={classes.descSection}>{projectConfigs.waxWallet.content}</p>
              <Button href="https://wallet.wax.io/" target="_blank" color="primary" variant="contained" size="large" classes={{ label: classes.buttonLabel}}>
                Set up your wallet
              </Button>
            </div>
        </div>
      </Grid>
    )
  }

    return (
        <div>
          {(projectConfigs) ?
            render()
            :
            null
          }
        </div>
    );
  }

export default Home