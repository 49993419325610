import { createWidget } from '@typeform/embed'
import '@typeform/embed/build/css/widget.css'
import { useContext, useEffect } from 'react'
import { UALContext } from 'ual-reactjs-renderer';

function TypeForm () {
    const ual = useContext(UALContext)
    // console.log('ualOnTypeForm', ual)
    const onReadyType = (e) => {
        // console.log('onReadyType')
    }

    const onSubmitType = (e) => {
        console.log('onReadyType')
    }

    useEffect (() => {
        createWidget('out8oEkI',
        {
            container: document.querySelector('.refDiv'),
            onReady: (e) => onReadyType(e),
            onSubmit: (e) => onSubmitType(e)
        })
    }, [])

    return(
        <div>
            <h2>Type Forms</h2>
            {(ual && ual.activeUser && ual.activeUser.accountName) ?
                <div>
                    <pre>{ual.activeUser.accountName}</pre>
                </div>
            : <pre>Not logged yet</pre>
            }
            <div id="refDiv" className="refDiv"></div>
        </div>
    )
}

export default TypeForm;