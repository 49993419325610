import { Fragment } from "react";

function Faq () {
    return (
        <Fragment>
            <h1>FAQ</h1>
        </Fragment>
    )
}

export default Faq