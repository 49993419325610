import { JsonRpc } from 'eosjs';
//import { TokenAsset } from '../domain';

class EosRpc {
    constructor() {
        const {
            REACT_APP_EOS_HTTP_ENDPOINT_PROTOCOL,
            REACT_APP_EOS_HTTP_ENDPOINT_HOST,
            REACT_APP_EOS_HTTP_ENDPOINT_PORT,
        } = process.env;

        this.rpc = new JsonRpc(
            `${REACT_APP_EOS_HTTP_ENDPOINT_PROTOCOL}://${REACT_APP_EOS_HTTP_ENDPOINT_HOST}:${REACT_APP_EOS_HTTP_ENDPOINT_PORT}`,
        );
    }

    // async getCurrencyBalance({ contract = 'eosio.token', account, symbol = 'EOS' }) {
    //     const results = await this.rpc.get_currency_balance(contract, account, symbol);
    //     return results.length ? TokenAsset.parse(results[0]) : new TokenAsset(0, symbol);
    // }

    async getAccount(account) {
        return this.rpc.get_account(account);
    }

    /**
     * TODO: Improve the way of how the data is fetched when a filter is passed in to better handle limit,
     *  at the moment we are just putting a very large limit, then filtering and finally implementing the limit
     *
     * @param {*} param0
     * @param {*} filterFn
     */
    async getTableRows(
        {
            code,
            scope,
            table,
            indexPosition,
            tableKey,
            lowerBound,
            upperBound,
            keyType,
            limit = 10,
            reverse,
        },
        filterFn = null,
        sort = null,
    ) {
        const mScope = scope || code;
        let mLimit = limit;
        if (filterFn || sort) {
            mLimit = Number.MAX_SAFE_INTEGER;
        }
        let results = await this.rpc.get_table_rows({
            json: true,
            code,
            scope: mScope,
            table,
            index_position: indexPosition,
            table_key: tableKey,
            lower_bound: lowerBound,
            upper_bound: upperBound,
            key_type: keyType,
            limit: mLimit,
            reverse,
        });

        if (filterFn) {
            let { rows } = results;
            rows = rows.filter(filterFn);
            results = {
                rows: rows.slice(0, limit),
                more: rows.length > limit,
            };
        }

        if (sort) {
            let compare = sort;
            if (typeof sort !== 'function') {
                let { field, desc } = sort;
                field = field.replace(/([A-Z])/g, '_$1').toLowerCase();
                desc = desc ? -1 : 1;
                // eslint-disable-next-line no-nested-ternary
                compare = (a, b) => (a[field] > b[field] ? 1 : a[field] < b[field] ? -1 : 0) * desc;
            }
            results.rows = results.rows.sort(compare);
        }

        return results;
    }

    async getOneTableRow(
        {
            code, scope, table, indexPosition, tableKey, upperBound, keyType, lowerBound, reverse,
        },
        filterFn,
    ) {
        const { rows } = await this.getTableRows(
            {
                code,
                scope,
                table,
                indexPosition,
                tableKey,
                lowerBound,
                upperBound,
                keyType,
                reverse,
                limit: 1,
            },
            filterFn,
        );

        return rows.length ? rows[0] : null;
    }
}

export default new EosRpc();
