import { Grid, CircularProgress, Button } from '@material-ui/core';
import { Fragment, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
// import openingVideo from "./atoms.mp4";

const useStyles = makeStyles((theme) => ({
    main: {
        background: 'gray',
        height: '100%',
        minHeight: '300px',
        minWidth: '300px',
        // width: '2000px',
    },
    mainContent: {
        background: 'black',
        height: '100vh',
        minHeight: '300px',
        minWidth: '300px',
        width: '100%',
        display: 'relative'
    },
    videoContainer: {
        minWidth: '100%',
        width: '100%',
        // width: '1200px',
    },
    containerCircularProgress: {
        width: '100%',
        textAlign: 'center'
    },
    circularProgress: {
        width: '10%',
        textAlign: 'center',
        color: '#F0F0F0',
    },
    containerCard: {
        background: '#F0F0F0',
        width: '30%',
        height: '25vh',
        margin: 'auto',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        padding: '30px',
        [theme.breakpoints.down('sm')]: {
            width: "90%",
        },
        // margin: '20%'
    },
    title: {
        fontSize: '1.5rem',
        fontWeight: '500',
        textAlign: 'center',
        width: '100%'
    },
    claimButton: {
        textAlign: 'center',
        marginTop: 5,
        marginBottom: 5
    },
}))

function OpeningVideo (props) {
    const {videoFinished, urlVideo} = props
    const [showingVideo, setShowingVideo] = useState(true)
    const [loading, setLoading] = useState(false)
    const classes = useStyles()

    const hideVideo = () => {
        setShowingVideo(false)
        // videoFinished()
    }

    const claimingPackages = () => {
        setShowingVideo(false)
        setLoading(true)
        videoFinished()
    }

    return(
    <Grid container direction="row" alignItems="center" className={!showingVideo ?  classes.mainContent : classes.main}>
        { showingVideo ?
            <video autoPlay onEnded={hideVideo} className={classes.videoContainer}>
                <source src={urlVideo} type="video/mp4" />
            </video> :
            <Fragment>
                {loading ?
                    <div className={classes.containerCircularProgress}>
                        <CircularProgress size="lg" className={classes.circularProgress} />
                    </div>
                    :
                    <div className={classes.containerCard}>
                        <p className={classes.title}>Claim your cards!</p>
                        <Grid container direction="row" justifyContent="center">
                            <Button fullWidth variant="contained" onClick={claimingPackages} className={classes.claimButton} color="secondary">Claim Cards</Button>                    
                        </Grid>
                    </div>
                }
            </Fragment>
        }
    </Grid>
    )
}

export default OpeningVideo