import Footer from "../layouts/Footer"
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    containerProgress: {
        height: '50vh',
        position: 'relative'
    },
    progress: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto'
    }
}))

export default function LoadingConfigsPage () {
    const classes = useStyles();
    return (
        <div>
            <div className={classes.containerProgress}>
                <CircularProgress size={80} color="primary" className={classes.progress} />
            </div>
            <Footer />
        </div>
    )
}