import { Fragment } from "react";

function Inventory () {
    return (
        <Fragment>
            <h1>Inventory</h1>
        </Fragment>
    )
}

export default Inventory