import { useContext } from "react";
import { UALContext } from 'ual-reactjs-renderer';
// import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import LoginView from '../components/open/loginView';
import PackagesList from '../components/open/packagesList';

const useStyles = makeStyles((theme) => ({
    main: {
        background: 'black',
        minHeight: '94vh'
    }
}))

function Open () {
    const ual = useContext(UALContext);
    const classes = useStyles();

    function isLogged () {
        return (ual && ual.activeUser)
    }

    return (
        <div className={classes.main}>
            {(!isLogged()) ?
                <LoginView />
            :
                <PackagesList />
            }
        </div>
    )
}

export default Open