import './App.css';
import { ThemeProvider } from '@material-ui/core/styles';
import { UALContext } from 'ual-reactjs-renderer';
import customTheme from './customTheme'
import { useContext, useEffect } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Redirect
} from "react-router-dom";

import {
  // TypeForm,
  ResponsesTypeForm,
  Open,
  Faq,
  Home,
  Inventory,
  Catalog
} from './pages'

import MainLayout from './layouts/MainLayout';
import { ProjectContext } from './context/projectContext';

function NotFound() {
  let location = useLocation();
  
  return <h2>No match for <code>{location.pathname}</code></h2>;
}

function App() {
  const myTheme = customTheme
  const ual = useContext(UALContext)
  const isLoggedIn = isLogged()
  const { configs, projectApi } = useContext(ProjectContext)
  const [projectConfigs, setProjectConfigs] = configs
  // let location = useLocation();
  function isLogged () {
    return (ual && ual.activeUser)
  }

  const fetchConfigs = async () => {
    const configs = await projectApi.getProjectConfigs()
    // setConfigs(configs)
    setProjectConfigs(configs)
  }

  useEffect(() => {
    if (!projectConfigs) {
      fetchConfigs()
    }
    // console.log('configs: ', configs)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // let location = new useLocation();
 

  return (
    <ThemeProvider theme={myTheme}>
      <Router>
      {/* <CheckIsLoggedIn> */}
      <MainLayout>
          {/* A <Switch> looks through its children <Route>s and
              renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/responsesTypeForm">
              {isLoggedIn ? <ResponsesTypeForm /> : <Redirect
                to={{
                  pathname: "/",
                  search: "?returnUrl=responsesTypeForm",
                }}
              />}
              {/* {isLoggedIn ? <ResponsesTypeForm /> :  <Home />} */}
            </Route>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/open">
              <Open />
              {/* <Open /> */}
            </Route>
            <Route exact path="/inventory">
              <Inventory />
            </Route>
            <Route exact path="/catalog">
              <Catalog />
            </Route>
            <Route exact path="/faq">
              <Faq />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
      </MainLayout>
      {/* </CheckIsLoggedIn> */}
      </Router>
    </ThemeProvider>
  );
}

export default App;
